const services = {
  nailServices: {
    acrylicServices: {
      fullSetGel: {
        displayName: "Full Set Gel",
        fullServiceName: "Acrylic Full Set Gel",
        price: 55,
        duration: 60,
        description:
          "This full set extends your nails by adding artificial tips and applying acrylic powder, finished with your choice of up to two gel colors. (If designs, specific shaping, and extra length is desired, please add on to services.)",
      },
      overlayGel: {
        displayName: "Gel Overlay",
        fullServiceName: "Acrylic Gel Overlay",
        price: 50,
        duration: 45,
        description:
          "This set enhances your nails by applying acrylic powder to your natural length, finished with your choice of up to two colors. (If designs and specific shaping as desired, please add on to services.)",
      },
      fullSetFrench: {
        displayName: "Full Set Gel French",
        fullServiceName: "Acrylic Full Set Gel French",
        price: 60,
        duration: 65,
        description:
          "This full set extends your nails by adding artificial tips and applying acrylic powder finished with pink and white French gel. (If designs, specific shaping, and extra length is desired, please add on to services.)",
      },
      fullSetRegularPolish: {
        displayName: "Full Set Regular Polish",
        fullServiceName: "Acrylic Full Set Regular Polish",
        price: 40,
        duration: 45,
        description:
          "This set extends your nails by adding artificial tips and applying acrylic powder, finished with your choice of regular polish. (If designs, specific shaping, and extra length is desired, please add on to services.)",
      },
      fullSetFrenchRegularPolish: {
        displayName: "Full Set Regular Polish French",
        fullServiceName: "Acrylic Full Set Regular Polish French",
        price: 50,
        duration: 50,
        description:
          "This set extends your nails by adding artificial tips and applying acrylic powder, finished with regular pink and white French polish. (If designs, specific shaping, and extra length is desired, please add on to services.)",
      },
      fillGel: {
        displayName: "Fill Gel",
        fullServiceName: "Acrylic Fill Gel",
        price: 45,
        duration: 45,
        description:
          "This service is a correction and rebalance on your existing acrylic nails. Finish off with your choice of gel polish. (If designs, specific shaping, and cut down is desired, please add on to services.)",
      },
      fillRegular: {
        displayName: "Fill Regular",
        fullServiceName: "Acrylic Fill Regular",
        price: 35,
        duration: 35,
        description:
          "This service is a correction and rebalance on your existing acrylic nails. Finish off with your choice of regular polish. (If designs, specific shaping, and cut down is desired, please add on to services.)",
      },
    },
    dipPowderServices: {
      fullDipSetExtensions: {
        displayName: "Full Dip Set with Extensions",
        fullServiceName: "Dip Powder Full Dip Set with Extensions",
        price: 55,
        duration: 60,
        description:
          "This set enhances your nails by adding artificial tips and applying your choice of up to two colors of dipping powder. (If designs, specific shaping, and extra length is desired, please add on to services.)",
      },
      fullDipSetFrench: {
        displayName: "Full Dip Set French",
        fullServiceName: "Dip Powder Full Dip Set French",
        price: 60,
        duration: 65,
        description:
          "This set enhances your nails by adding artificial tips and applying pink and white dip powder. (If designs, specific shaping, and extra length is desired, please add on to services.)",
      },
      dipOverlay: {
        displayName: "Dip Overlay",
        fullServiceName: "Dip Powder Dip Overlay",
        price: 50,
        duration: 45,
        description:
          "This set enhances your nails by applying your choice of up to two colors of dipping powder on your natural length. (If designs and specific shaping is desired, please add on to services.)",
      },
    },
    gelXServices: {
      fullSetGelXSoftGel: {
        displayName: "Full Set X/Soft Gel",
        fullServiceName: "Gel X Full Set X/Soft Gel",
        price: 65,
        duration: 60,
        description:
          "This service is a full coverage tip that goes over your whole natural nail and is applied with gel. Gel x allows you to add length.",
      },
      soakOffPlusGelXFullSet: {
        displayName: "Soak off + gel x full set",
        fullServiceName: "Gel X Soak off + gel x full set",
        price: 75,
        duration: 80,
        description:
          "This service is for us to remove your old set and put a new full set on. If we have to remove acrylic it will be an additional $5.00.",
      },
      refillGelXSoftGel: {
        displayName: "Refill Gel X/Soft gel",
        fullServiceName: "Gel X Refill Gel X/Soft gel",
        price: 55,
        duration: 60,
        description:
          "This service will consist of us laying new gel on your 2 week growth and reshaping.",
      },
    },
    rubberServices: {
      rubberManicure: {
        displayName: "Rubber manicure",
        fullServiceName: "Rubber manicure",
        price: 55,
        duration: 60,
        description:
          "This service is a structured manicure. It's a thicker gel that helps natural nails from breaking as easily. We will still go over cuticles, shape, and polish.",
      },
      soakOffRubberManicure: {
        displayName: "Soak off + Rubber manicure",
        fullServiceName: "Rubber Soak off + Rubber manicure",
        price: 65,
        duration: 80,
        description:
          "This service is for us to remove your old flex gel and the structured manicure is done again",
      },
    },
    manicures: {
      regular: {
        displayName: "Regular",
        fullServiceName: "Regular Manicure",
        price: 30,
        duration: 25,
        description:
          "This treatment includes nail trimming and shaping, cuticle grooming, lotion massage, hot towel wrap and your choice of up to two regular polish.",
      },
      regularFrench: {
        displayName: "Regular French",
        fullServiceName: "Regular French Manicure",
        price: 25,
        duration: 25,
        description:
          "This treatment includes nail trimming and shaping, cuticle grooming, lotion massage, hot towel wrap and regular pink and white French tips.",
      },
      gel: {
        displayName: "Gel",
        fullServiceName: "Gel Manicure",
        price: 50,
        duration: 30,
        description:
          "This treatment includes nail trimming and shaping, cuticle grooming, lotion massage, hot towel wrap and your choice of up to two gel polish.",
      },
      gelFrench: {
        displayName: "Gel French",
        fullServiceName: "Gel French Manicure",
        price: 55,
        duration: 30,
        description:
          "This treatment includes nail trimming and shaping, cuticle grooming, lotion massage, hot towel wrap and gel pink and white French tips.",
      },
      deluxeRegular: {
        displayName: "Deluxe Regular",
        fullServiceName: "Deluxe Regular Manicure",
        price: 35,
        duration: 35,
        description:
          "This treatment includes nail trimming and shaping, cuticle grooming, sugar scrub, paraffin wax, lotion oil massage, hot towel wrap and your choice of up to two regular polish.",
      },
      deluxeGel: {
        displayName: "Deluxe Gel",
        fullServiceName: "Deluxe Gel Manicure",
        price: 60,
        duration: 35,
        description:
          "This treatment includes nail trimming and shaping, cuticle grooming, sugar scrub, paraffin wax, lotion oil massage, hot towel wrap and your choice of up to two gel polish.",
      },
    },
    polishChanges: {
      regularPolish: {
        nails: {
          displayName: "Nails",
          fullServiceName: "Regular Polish Change Nails",
          price: 15,
          duration: 15,
          description:
            "This service includes nail trimming, shaping and your choice of up to two regular polish.",
        },
        frenchTipsNails: {
          displayName: "French Tips Nails",
          fullServiceName: "Regular Polish Change French Tips Nails",
          price: 15,
          duration: 15,
          description:
            "This service includes nail trimming, shaping and regular pink and white French tips",
        },
      },
      gelPolish: {
        nails: {
          displayName: "Nails",
          fullServiceName: "Gel Polish Change Nails",
          price: 25,
          duration: 20,
          description:
            "This service includes nail trimming, shaping and your choice of up to two gel polish.",
        },
        frenchTipsNails: {
          displayName: "French Tips Nails",
          fullServiceName: "Gel Polish Change French Tips Nails",
          price: 45,
          duration: 20,
          description:
            "This service includes nail trimming, shaping and gel pink and white French tips.",
        },
      },
    },
  },
  addOnServices: {
    nail: {
      repair: {
        displayName: "Repair",
        fullServiceName: "Add on - Repair",
        price: 5,
        duration: 10,
        description:
          "Please add this service if there are any nail enhancements in need of replacing.",
      },
      cutDown: {
        displayName: "Cut Down",
        fullServiceName: "Add on - Cut Down",
        price: 5,
        duration: 5,
        description:
          "Please add this service if you need to shorten your nail enhancements.",
      },
      shape: {
        displayName: "Shape",
        fullServiceName: "Add on - Shape",
        price: 5,
        duration: 5,
        description:
          "Please add this service if you desire specific nail shapes such as coffin, square, oval, squoval, almond or stiletto.",
      },
      extraLength: {
        displayName: "Extra Length",
        fullServiceName: "Add on - Extra Length",
        price: 10,
        duration: 10,
        description:
          "Please add this service if you desire medium, long, or extra long nails when receiving a new set.",
      },
      designs: {
        displayName: "Designs",
        fullServiceName: "Add on - Designs",
        price: 10,
        duration: 10,
        description:
          "Please add this service if you desire nail designs. Feel free to bring inspiration nail pics.",
      },
    },
    pedicure: {
      gelPolishPedicure: {
        displayName: "Gel Polish Pedicure",
        fullServiceName: "Add on - Gel Polish Pedicure",
        price: 10,
        duration: 10,
        description:
          "Please add this service if you wish to have gel polish instead of regular polish for your pedicure.",
      },
      paraffinWax: {
        displayName: "Paraffin Wax",
        fullServiceName: "Add on - Gel Polish Pedicure",
        price: 8,
        duration: 5,
        description:
          "Assist in keeping your hands and feet hydrated in the colder months, it also helps them to retain moisture during the dryer summer season.",
      },
    },
  },

  pedicureServices: {
    pedicures: {
      basic: {
        displayName: "Basic",
        fullServiceName: "Basic Pedicure",
        price: 35,
        duration: 25,
        description:
          "This pedicure includes nail trimming and shaping, cuticle grooming, lotion oil massage, hot towel wrap and your choice of regular polish.",
      },
      regular: {
        displayName: "Regular",
        fullServiceName: "Regular Pedicure",
        price: 45,
        duration: 35,
        description:
          "This pedicure includes nail trimming and shaping, cuticle grooming, callus treatment, lotion oil massage, hot towel wrap and your choice of regular polish.",
      },
      spa: {
        displayName: "Spa",
        fullServiceName: "Spa Pedicure",
        price: 60,
        duration: 40,
        description:
          "This pedicure includes nail trimming and shaping, cuticle grooming, callus treatment, sugar scrub, paraffin wax, hot stone massage, hot towel wrap and your choice of regular polish.",
      },
      deluxeSpaGel: {
        displayName: "Deluxe Spa Gel",
        fullServiceName: "Deluxe Spa Gel Pedicure",
        price: 70,
        duration: 60,
        description:
          "This pedicure includes nail trimming and shaping, cuticle grooming, callus treatment, Cracked Heel Treatment, sugar scrub, paraffin wax, hot stone massage, hot towel wrap and your choice of gel polish.",
      },
    },
    polishChange: {
      regularPolish: {
        toes: {
          displayName: "Toes",
          fullServiceName: "Regular Polish Change Toes",
          price: 25,
          duration: 15,
          description:
            "This service includes nail trimming, shaping and your choice of up to two regular polish.",
        },
        frenchTipsToes: {
          displayName: "French Tips Toes",
          fullServiceName: "Regular Polish Change French Tips Toes",
          price: 30,
          duration: 20,
          description:
            "This service includes nail trimming, shaping and regular pink and white French tips",
        },
      },
      gelPolish: {
        toes: {
          displayName: "Toes",
          fullServiceName: "Gel Polish Change Toes",
          price: 25,
          duration: 20,
          description:
            "This service includes nail trimming, shaping and your choice of up to two gel polish.",
        },
        frenchTipsToes: {
          displayName: "French Tips Toes",
          fullServiceName: "Gel Polish Change French Tips Toes",
          price: 25,
          duration: 20,
          description:
            "This service includes nail trimming, shaping and gel pink and white French tips",
        },
      },
    },
  },
  kidServices: {
    regularPolish: {
      manicure: {
        displayName: "Manicure",
        fullServiceName: "Kid Regular Polish Manicure",
        price: 15,
        duration: 10,
        description:
          "This treatment includes nail trimming and shaping, cuticle grooming, lotion massage, hot towel wrap  and your choice of up to two regular polish.",
      },
      pedicure: {
        displayName: "Pedicure",
        fullServiceName: "Kid Regular Polish Pedicure",
        price: 25,
        duration: 15,
        description:
          "This pedicure includes nail trimming and shaping, cuticle grooming, lotion oil massage, hot towel wrap and your choice of up to two regular polish.",
      },
    },
    gelPolish: {
      manicure: {
        displayName: "Manicure",
        fullServiceName: "Kid Gel Polish Manicure",
        price: 25,
        duration: 15,
        description:
          "This treatment includes nail trimming and shaping, cuticle grooming, lotion massage, hot towel wrap and your choice of up to two gel polish.",
      },
      pedicure: {
        displayName: "Pedicure",
        fullServiceName: "Kid Gel Polish Pedicure",
        price: 35,
        duration: 25,
        description:
          "This pedicure includes nail trimming and shaping, cuticle grooming, lotion oil massage, hot towel wrap and your choice of up to two gel polish.",
      },
    },
    polishChanges: {
      regularPolish: {
        nails: {
          displayName: "Nails",
          fullServiceName: "Kid Regular Polish Change Nails",
          price: 20,
          duration: 10,
          description:
            "This service includes nail trimming, shaping and your choice of up to two regular polish.",
        },
        toes: {
          displayName: "Toes",
          fullServiceName: "Kid Regular Polish Change Toes",
          price: 25,
          duration: 10,
          description:
            "This service includes nail trimming, shaping and your choice of up to two regular polish.",
        },
      },
      gelPolish: {
        nails: {
          displayName: "Nails",
          fullServiceName: "Kid Gel Polish Change Nails",
          price: 17,
          duration: 15,
          description:
            "This service includes nail trimming, shaping and your choice of up to two gel polish.",
        },
        toes: {
          displayName: "Toes",
          fullServiceName: "Kid Gel Polish Change Toes",
          price: 20,
          duration: 15,
          description:
            "This service includes nail trimming, shaping and your choice of up to two gel polish.",
        },
      },
    },
  },

  menServices: {
    pedicure: {
      basic: {
        displayName: "Basic",
        fullServiceName: "Men Basic Pedicure",
        price: 35,
        duration: 30,
        description:
          "This pedicure includes nail trimming and shaping, cuticle grooming, lotion oil massage and hot towel wrap.",
      },
      regular: {
        displayName: "Regular",
        fullServiceName: "Men Regular Pedicure",
        price: 45,
        duration: 35,
        description:
          "This pedicure includes nail trimming and shaping, cuticle grooming, callus treatment, lotion oil massage and hot towel wrap.",
      },
      spa: {
        displayName: "Spa",
        fullServiceName: "Men Spa Pedicure",
        price: 60,
        duration: 40,
        description:
          "This pedicure includes nail trimming and shaping, cuticle grooming, callus treatment, sugar scrub, paraffin wax, lotion oil massage and hot towel wrap",
      },
    },
    manicure: {
      basic: {
        displayName: "Basic",
        fullServiceName: "Men Basic Manicure",
        price: 30,
        duration: 25,
        description:
          "This treatment includes nail trimming and shaping, cuticle grooming, lotion massage and hot towel wrap.",
      },
      deluxe: {
        displayName: "Deluxe",
        fullServiceName: "Men Deluxe Manicure",
        price: 45,
        duration: 35,
        description:
          "This treatment includes nail trimming and shaping, cuticle grooming, sugar scrub, paraffin wax, lotion oil massage and hot towel wrap.",
      },
    },
  },
  soakOffServices: {
    acrylicSoakOff: {
      displayName: "Acrylic",
      fullServiceName: "Soak Off Acrylic",
      price: 10,
      duration: 20,
      description:
        "Using an e-file, we remove the top layer of product and wrap each nail with cotton, acetone and clips/foil. Any remaining product will be buffed and the process will repeat if necessary.",
    },
    dipPowderSoakOff: {
      displayName: "Dip Powder",
      fullServiceName: "Soak Off Dip Powder",
      price: 5,
      duration: 15,
      description:
        "Using an e-file, we remove the top layer of product and wrap each nail with cotton, acetone and clips/foil. Any remaining product will be buffed and the process will repeat if necessary",
    },
    gelSoakOffNails: {
      displayName: "Gel Nail Polish",
      fullServiceName: "Soak Off Gel Nail Polish",
      price: 5,
      duration: 10,
      description:
        "Using an e-file, we remove the top layer of product and wrap each nail with cotton, acetone and clips/foil. Any remaining product will be buffed and the process will repeat if necessary.",
    },
    gelSoakOffToes: {
      displayName: "Gel Toe Polish",
      fullServiceName: "Soak Off Gel Toe Polish",
      price: 5,
      duration: 10,
      description: "Using an e-file, we gently remove the gel polish on toes.",
    },
  },
};

const monthsNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export { services, monthsNames };
